export function KeysDialog(dialog) {
  // Private Variables
  let that = this; // generic pointer back to this function

  // Public variables

  this.show = function (sysConfig) {
    dialog.modal("show");
  };

  // Buttons
}
